import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RecuperarUsuarioFacadeService } from './recuperar-usuario-facade.service';
import { Router } from '@angular/router';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';

@Component({
  selector: 'app-recuperar-usuario',
  templateUrl: './recuperar-usuario.component.html',
  styleUrls: ['./recuperar-usuario.component.css']
})
export class RecuperarUsuarioComponent implements OnInit {

  public usuario = new FormControl('');
  public informacionUsuario: any;
  public alerta:string = '';
  public error:boolean = false;

  constructor(public recuperarUsuarioFacade:RecuperarUsuarioFacadeService, public router:Router, public toast:ToastrServiceLocal) { }

  ngOnInit(): void {
  }

  obtenerUsuario(){
    if(this.usuario.invalid){
      this.toast.mensajeWarning('Es requerido ingresar el numero de identificación', '');
    }
    this.recuperarUsuarioFacade.ObtenerUsuario(this.usuario.value, (result)=>{
      if(result){
        this.error = false;
        this.informacionUsuario = result;
      }else{
        this.error = true;
        this.alerta = 'El numero de identificación ingresado no coincide con ningún usuario';
      }
    })
  }

  
  navegar(){
    this.router.navigateByUrl('/public-pages/files/login');
  }

  regresar(){
    this.error = false;
    this.informacionUsuario = null;
    this.alerta = ' ';
  }

}
