<div>
    <div mat-dialog-title class="titleDialog">
        Ubicaciones
    </div>
    <mat-card>
        <mat-card-content>
            <div *ngIf="(puntoVentaService.responseCargando$ | async)">
                <app-loading [data]="4"></app-loading>
            </div>
            <div *ngIf="!(puntoVentaService.responseCargando$ | async)">
                <select class="custom-select" multiple>
                    <option *ngFor="let item of (puntoVentaService.responsePuntoVenta$ | async); let i = index;"
                        (click)="seleccionarPuntoVenta(item)">
                        <b class="titulo">{{i + 1}}</b>
                        <span> Nombre Sucursal: {{item.nombreSucursal}}</span>
                        <span> Ubicación: {{item.ubicacion}}</span>
                    </option>

                </select>
            </div>

        </mat-card-content>
    </mat-card>

</div>