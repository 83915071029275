import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from 'src/app/Model/dataApi';
import { AuthService } from 'src/app/services/auth.service';
import { DataApiService } from 'src/app/services/data-api.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CambioPasswordFacadeService {

  constructor(private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal, private _dataApi: DataApiService, public auth: AuthService) { }

  ngOnDestroy(): void {
    this.suscripciones.unsubscribe();
  }

  public suscripciones: Subscription = new Subscription();

  private Action$ = new BehaviorSubject<any>({});
  public responseAction$: Observable<any> = this.Action$.asObservable();

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  cambioClaveput(params?: any) {
    this.Action$.next({});
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/recuperarpass`, params, 7).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (result.hasError === false) {
          this.Action$.next(result);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al cambiar la contraseña', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  cambioClave(params?: any) {
    this.Action$.next({});
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/admin/pass`, params, 7).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (result.hasError === false) {
          this.Action$.next(result);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al cambiar la contraseña', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  nuevoPin(params?: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/admin/pinDescription`, params, 7).pipe(
      tap((result: DataApi | any) => {
        if (result.hasError === false) {
          this._toast.clearToasts();
          this.Action$.next(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error en la asignación de su pin de seguridad', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  validarToken(params: any, callback) {
    this._toast.mensajeLoading('Cargando', 'Procesando la información');
    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/recuperarpass/`, params, 7).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts()
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach(element => {
            this._toast.mensajeInfo(element.descripcion, '');
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this.auth.CerrarSesion();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al validar los datos', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
}
