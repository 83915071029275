<div class="toast-container">
  <div class="Message-icon">
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  <div class="Message-body">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="text-center">
      <p>{{ message }}</p>
    </div>
    <p class="text-center">{{ title }}</p>
  </div>

</div>