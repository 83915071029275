import { Component, Input, OnInit } from '@angular/core';
import { bottomActions } from '../../Model/Shared/bottomActions.interface';

@Component({
  selector: 'app-actions-button',
  templateUrl: './actions-button.component.html',
  styleUrls: ['./actions-button.component.css']
})
export class ActionsButtonComponent implements OnInit {
  @Input() itemButtom: bottomActions[];
  constructor() { }

  ngOnInit(): void {
  }

}
