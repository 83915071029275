import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from 'src/app/Model/dataApi';
import { puntoVentaInterface } from 'src/app/Model/Shopping/shopping.interface';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { environment } from 'src/environments/environment';
import { DataApiService } from '../data-api.service';
import { ToastrServiceLocal } from '../toast/toastr.service';

@Injectable({
  providedIn: 'root'
})
export class ModalPuntoVentaService {


  constructor(public _toast: ToastrServiceLocal, public _dataApi: DataApiService,
    public _mensajesHttp: MensajesHttpService) { };

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private PuntoVenta$ = new BehaviorSubject<puntoVentaInterface[]>([]);
  public responsePuntoVenta$: Observable<puntoVentaInterface[]> = this.PuntoVenta$.asObservable();

  private PuntoVentaLocal$ = new BehaviorSubject<puntoVentaInterface>({nombreSucursal: '', ubicacion: '', tipoPuntoVenta: '', tipoUbicacion: ''});
  public responsePuntoVentaLocal$: Observable<puntoVentaInterface> = this.PuntoVentaLocal$.asObservable();

  mostrarPuntosVenta(params?: string) {
    // this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`${environment.site}/api/admin/${environment.processEnv}/v1/ubicaciones/usuario`, params, 1).pipe(
      tap((result: DataApi | any) => {
        if (result.hasError === false) {
          this.PuntoVenta$.next(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al mostrar los puntos de venta', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  setearValorSeleccionado(id,item) {
    if (id === 1) {
      this.PuntoVentaLocal$.next(item);
    }
    if (id === 2) {
      this.PuntoVentaLocal$.next(JSON.parse(item));
    }
  }

  getPuntoVentaLocal() {
    let puntoVenta = JSON.parse(localStorage.getItem("puntoVenta"));
    return puntoVenta;
  }

}
