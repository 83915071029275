import { Injectable } from '@angular/core';
import { LoadingToastComponent } from '../../shared/loading-toast/loading-toast.component';
import { WelcomeToastComponent } from '../../shared/welcome-toast/welcome-toast.component';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrServiceLocal {

  constructor(private toastr: ToastrService) { }

  mensajeSuccess(texto: string, titulo: string, config?: Object) {
    this.toastr.success(texto, titulo, config);
  }

  mensajeLoading(texto: string, titulo: string, config?: Object){
    this.toastr.show(texto, titulo, { toastComponent: LoadingToastComponent, timeOut: 500000})
  }

  mensajeBienvenida(texto: string, titulo: string, config?: Object) {
    this.toastr.show(texto, titulo, { toastComponent: WelcomeToastComponent, timeOut: 5000 })
  }

  mensajeError(texto: string, titulo: string, config?: Object) {
    this.toastr.error(texto, titulo, config);
  }

  mensajeWarning(texto: string, titulo: string, config?: Object) {
    this.toastr.warning(texto, titulo, config);
  }

  mensajeInfo(texto: string, titulo: string, config?: Object) {
    this.toastr.info(texto, titulo, config);
  }

  clearToasts() {
    this.toastr.clear();
  }
}
