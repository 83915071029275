import { HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResponseInterceptorService {
  
  private Token$ = new BehaviorSubject<string>("");
  public responseToken$: Observable<string> = this.Token$.asObservable();

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    return next.handle(req).pipe(
      tap((result: HttpResponse<any>) => {
        // console.log(result);
        if (result?.body?.refreshToken) {
          this.refrescarToken(result?.body?.refreshToken);
        }
      })
    );
  }

  refrescarToken(token) {
    if (token != '') {
      // console.log('entro a refrescar');
      localStorage.setItem(environment.tokenLocal,token);
    }
  }
}
