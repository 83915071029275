import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenGuard implements CanActivateChild {

  constructor(public authService:AuthService){}

  canActivateChild(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let estado = (localStorage.getItem(environment.tokenLocal) != null) ? true : false;


    if(estado === false){
      this.authService.CerrarSesion();
    }
    return estado;
  }
  
}
