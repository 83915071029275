import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { environment } from 'src/environments/environment';
import { PinSeguridadFacadeService } from './pin-seguridad-facade.service';

@Component({
  selector: 'app-pin-seguridad',
  templateUrl: './pin-seguridad.component.html',
  styleUrls: ['./pin-seguridad.component.css']
})
export class PinSeguridadComponent implements OnInit, OnDestroy {
  public pinForm: FormGroup;
  public idTipoAccion: number;
  public dataUsuario: any;
  public suscripciones: Subscription = new Subscription();
  public hide = true;
  public hide1 = true;



  constructor(public pinSeguridadFacade: PinSeguridadFacadeService, public activatedRoute: ActivatedRoute, private _toast: ToastrServiceLocal, public router: Router) {
    this.idTipoAccion = Number(this.activatedRoute.snapshot.paramMap.get('idTipoAccion'));

    this.pinForm = new FormGroup({
      pin: new FormControl('', [Validators.required]),
      confirmarPin: new FormControl('', [Validators.required]),

    });
    this.obtenerDataLocal();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.suscripciones.unsubscribe();
  }

  validarFormularioPin() {
    if (this.pinForm.invalid) {
      this.pinForm.markAllAsTouched();
      this._toast.mensajeWarning('', 'Es necesario completar los campos requeridos');
      return false;
    }
    else if (this.pinForm.value.pin != this.pinForm.value.confirmarPin) {
      this.pinForm.markAllAsTouched();
      this._toast.mensajeWarning('', 'Los codigos PIN ingresados no coinciden');
      return false
    }
    else {
      return true;
    }
  }

  cambioPassWordPin() {

    if (this.validarFormularioPin() === false) {
      return;
    }

    
    console.log({
      usuario: this.dataUsuario.Usuario,
      valor: [
        {
          pin: this.pinForm.value.pin,
          usuario: this.dataUsuario.Usuario

        }
      ]
    });

    this.pinSeguridadFacade.cambiarPin({
      usuario: this.dataUsuario.Usuario,
      valor: [
        {
          pin: this.pinForm.value.pin,
          usuario: this.dataUsuario.Usuario

        }
      ]
    });

    this.suscripciones.add(
      this.pinSeguridadFacade.responseAction$.subscribe((result)=>{
        if (result.hasError === false) {
          if (this.idTipoAccion === 1) {
            this.router.navigateByUrl('/login');
          } else {
            this.router.navigateByUrl('/mosaicMenu');
          }
        }
      })
    );

  }

  obtenerDataLocal() {
    this.dataUsuario = JSON.parse(localStorage.getItem("data_go504"));
    if (!this.dataUsuario) {
      this.router.navigateByUrl('/login');
    }
  }

  navegar() {
    if (localStorage.getItem(environment.apiUrlSecurity)) {
      this.router.navigateByUrl('/mosaicMenu');
    } else {
      this.router.navigateByUrl('/login');
    }
  }

}
