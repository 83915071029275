import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataApi } from '../Model/dataApi';
import { MensajesHttpService } from '../utils/mensajesHttp/mensajes-http.service';
import { SeguridadService } from './seguridad/seguridad.service';
import { ToastrServiceLocal } from './toast/toastr.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  private Action$ = new BehaviorSubject<any>({});
  public responseAction$: Observable<any> = this.Action$.asObservable();

  constructor(public router: Router, public seguridadService: SeguridadService, public _toast: ToastrServiceLocal, 
    public _mensajesHttp: MensajesHttpService) { }

  CerrarSesion(){
    let site = localStorage.getItem("site");
    if(site === "1"){
      this.router.navigateByUrl('login');
    }else if(site === "2"){
      this.router.navigateByUrl('public-pages/files/login');
    }
    else{
      this.router.navigateByUrl(environment.rutaDefecto);

    }
    
    localStorage.clear();
  }

  Login(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', '');

    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/auth/login/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        localStorage.setItem("token_go504", result.token);
        localStorage.setItem("data_go504", JSON.stringify(result.data));
        localStorage.setItem("menusTotales", JSON.stringify(result?.menus));
        localStorage.setItem("modulos", result?.data?.modulo);
        localStorage.setItem("site", "1");
        localStorage.setItem("soloLectura",result?.data?.soloLectura);

        if (result.data.cambioContrasena === true) {
          this.router.navigateByUrl('/cambioPassword/1');
        } else {
          this.router.navigateByUrl('/mosaicMenu');
        }
        // this._toast.mensajeSuccess('Bienvenido', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al entrar al sistema', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  LoginPublic(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', '');

    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/auth/login/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        localStorage.setItem("token_go504", result.token);
        localStorage.setItem("data_go504", JSON.stringify(result.data));
        localStorage.setItem("menusTotales", JSON.stringify(result?.menus));
        localStorage.setItem("modulos", result?.data?.modulo);
        localStorage.setItem("site", "2");

        if (result.data.cambioContrasena === true) {
          this.router.navigateByUrl('/cambioPassword/1');
        } else {
          this.router.navigateByUrl('template/afiliados/mi-perfil');
        }
        // this._toast.mensajeSuccess('Bienvenido', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al entrar al sistema', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  obtenerMenus(){
    return JSON.parse(localStorage.getItem("menusTotales"));
  }

  validarSitio(){
    let sitio = JSON.parse(localStorage.getItem("site") || null);

    if(sitio === 2){
      this.router.navigateByUrl('template/afiliados/mi-perfil');
    }

    return;
  }

  solicitudCambioPass(params: any) {
    this.Action$.next({})
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', '');
    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/recuperarpass/pass/`, params).pipe(
      tap((result: DataApi | any) => {
 /*    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/recuperarpass/pass`, params).pipe(
      tap((result: DataApi | any) => { */
        console.log("Resultado",result)
        this._toast.clearToasts();
        this._toast.mensajeSuccess(`${result?.data?.table0[0]?.descripcion || '' } `,'')
        this.Action$.next(result);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al solicitar el cambio de contraseña', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  solicitudCambioPassSinToken(params: any) {
    this.Action$.next({})
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', '');
    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/recuperarpass`, params).pipe(
      tap((result: DataApi | any) => {
 /*    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/recuperarpass/pass`, params).pipe(
      tap((result: DataApi | any) => { */
        console.log("Resultado",result)
        this._toast.clearToasts();
        this._toast.mensajeSuccess(`${result?.data?.table0[0]?.descripcion || '' } `,'')
        this.Action$.next(result);
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al solicitar el cambio de contraseña', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }


  resetearPassword(params: any, callback) {
    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/admin/pass/reset`, params).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result.data.table0);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.Cargando$.next(false);
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al resetear la contraseña', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  recuperarInformacionUsuario(params: any, callback) {
    this.Cargando$.next(true);
    const request$ = this.seguridadService.GetDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/recuperarpass/usuario/`, params).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Cargando$.next(false);
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al validar la información de colegiación', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  validacionInformacionUsuario(params: any, callback) {
    this.Cargando$.next(true);
    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/recuperarpass/validation`, params).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result.data.table0);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.Cargando$.next(false);
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al validar la información de colegiación', '');
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

}
